@media screen and (max-width: 600px) {
  .card {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f5f8ff;
  }
}

@media screen and (min-width: 601px) {
  .card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f5f8ff;
    justify-content: center;
  }
}

.welcome {
  color: rgb(255 255 255);
  font-size: 3rem;
  line-height: 1;
  font-weight: 600;
  text-align: left;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  white-space: nowrap;
}

.sign_up_token {
  padding-bottom: 1rem;
}

.button-screebot-blue {
  background: #645feb;
  border-radius: 50px;
  padding: 8px 30px;
  color: white;
  border-width: 0px;
  cursor: pointer;
  margin: 0 auto;
  font-weight: 500;
  width: 100%;
}

.button-screebot-blue:disabled {
  opacity: 0.5;
}

.button-screebot-blue:hover {
  background: #544efc;
  transition: all 0.2s;
}

.input-screebot {
  background: #ffffff;
  border: 0.5px solid rgba(103, 103, 103, 0.3);
  border-radius: 50px;
  font-size: 12px;
  padding: 0.5rem 1.15rem;
}

.text-screebot-grey {
  color: #676767;
}

.text-screebot-blue {
  color: #645feb;
}

input[type="email"] {
  margin: 0;
}

a {
  text-decoration: none;
}

.field {
  display: flex;
}
