.cards-admin {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid #e2e5eb;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.25);
}

.card-admin {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.2s;
}
/* .card-admin:hover {
  box-shadow: 0px 10px 20px rgb(207, 206, 230);
  transform: scale(1.01);
} */

/* .admin-input {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #2b3e60;
  padding: 0.75rem 1rem;
  min-width: 20rem;
} */

/* DESIGN INSIDE CARDS */
.card-admin * {
  /* color: #515461; */
  font-size: 14px;
}

.admin-user-image-badges {
  display: flex;
}

.admin-user-image-badges img {
  border-radius: 50%;
}

.admin-user-badge-status,
.admin-user-badge-plan {
  padding: 2px 12px;
  margin: 3px 0;
  border-radius: 50px;
  color: #ffffff;
  font-size: 12px;
}

.admin-user-badge-status {
  background-color: #11a711;
}

.admin-user-badge-plan {
  background-color: #11a711;
}

.admin-user-badge-plan-false {
  background-color: rgb(153 27 27);
  padding: 2px 12px;
  margin: 3px 0;
  border-radius: 50px;
  color: #ffffff;
  font-size: 12px;
}

.admin-user-name span.admin-user-last-name {
  color: #645feb;
  text-transform: uppercase;
}

.admin-user-token span {
  color: #645feb;
}

.admin-user-creation-date {
  color: #645feb;
}

.panel-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: left;
  padding-bottom: 1rem;
}

.button-panel {
  display: flex;
  padding: 10px 30px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid #e2e5eb;
  background: #fff;
  color: #486497;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.25);
}

.button-panel-active {
  color: #ffffff;
  background: #486497;
}

.button-panel:hover {
  color: #ffffff;
  background: #486497;
  cursor: pointer;
}

.dashboard-admin-card {
  background: #ffffff;
  border-radius: 24px;
  padding: 1.5rem;
  width: 100%;
}

.dashboard-admin-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #5b5c6d;
  text-align: left;
  padding-bottom: 1rem;
}

.dashboard-admin-number {
  /* font-family: 'Nunito Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #363565;
  padding-right: 2rem;
}

.admin-forfait-card {
  background: #ffffff;
  border-radius: 24px;
  padding: 1rem;
  width: 100%;
}

.admin-forfait-card-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #5b5c6d;
  text-align: left;
  padding-bottom: 1rem;
}

.admin-forfait-card-users {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.admin-forfait-card-user {
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
  justify-content: space-between;
  padding: 8px 4px;
}

.forfait-0 {
  background: #ffffff;
  border-radius: 12px;
}

.forfait-1 {
  background: #f8f8fb;
  border-radius: 12px;
}

.history-admin-user span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  display: flex;
}

.display-btn {
  width: 0.8px;
  height: 20px;
  background: #e2e5eb;
}
