.h-100 {
  height: 100% !important;
}

.rounded-lg {
  border-radius: 15px !important;
}
.bg-white {
  background: white !important;
}
.ml-auto {
  margin-left: auto;
}

.bg-transparent {
  background-color: transparent !important;
}

.label {
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.input {
  padding: 12px 40px 12px 20px !important;
  gap: 10px !important;
  font-size: 0.875rem;
  width: 100%;
}

.bubble {
  padding: 4px 10px;
  border-radius: 50px;
  column-gap: 10px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: row;
}

.b-small {
  padding: 2px 8px;
  font-size: 10px;
}

.b-new {
  background: linear-gradient(90deg, #f9ac39 0%, #fd6c42 63.03%, #ff4747 100%);
}
.b-seo {
  background-color: #3366ff;
}
.b-blog {
  background-color: #bc39f9;
}
.b-social {
  background-color: #2ed4c1;
}
.b-ecommerce {
  background-color: #ff9533;
}

.react-dropdown-select-dropdown {
  border-radius: 10px !important;
  padding: 1rem 1.25rem !important;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  z-index: 10 !important;
  box-shadow: none !important;
  border: 1px solid #e2e5eb !important;
}
.react-dropdown-select-option {
  margin: 0 0 0 3px !important;
}
.react-dropdown-select-item {
  text-align: left;
  padding: 5px 0px !important;
  color: #2b3e60 !important;
}
.react-dropdown-select-item-selected {
  background: white !important;
  font-weight: 600;
}
.react-dropdown-select-dropdown-handle {
  margin: unset !important;
}
.react-dropdown-select-item-disabled {
  background: none !important;
  cursor: not-allowed !important;
  font-style: italic !important;
  width: fit-content;
  filter: blur(2px);
  color: rgba(0, 0, 0, -0.5);
}
.react-dropdown-select-item-disabled ins {
  display: none; /* Hide the "disabled" text inside the <ins> element */
}

/* .react-dropdown-select-item-disabled::after {
  content: "(Disabled)";
  color: #dc2626;
  margin-left: 5px;
} */
.css-z2hyoe-ItemComponent:hover,
.css-z2hyoe-ItemComponent:focus {
  font-weight: 600 !important;
  background: white !important;
}
.css-wmy1p7-ReactDropdownSelect:focus,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
}
.react-dropdown-select-input {
  width: 0px !important;
  height: 0px !important;
}

.bg-img-feature {
  display: flex;
  width: 3.125rem;
  height: 3.125rem;
  max-width: 3.125rem;
  max-height: 3.125rem;
  padding: 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  border-radius: 3.125rem;
  background: #f6f7ff;
}

.bg-img-product {
  display: flex;
  width: 3.125rem;
  height: 3.125rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  background: #fff;
}

.bg-img-history-paginated {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.mt-auto {
  margin-top: auto;
}
.pb-4 {
  padding-bottom: 4rem;
}

.pb-1 {
  padding-bottom: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-return-button {
  padding-left: 1.25rem;
  padding-right: 1.875em;
}

.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 1.5rem;
}

.project-modal {
  display: flex;
  padding: 0.75rem 1rem;
  flex-direction: column;
  text-align: left;
  gap: 0.875rem;
  background: #fff;
}

.btn {
  transition: all 0.2s !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-medium {
  height: 2.75rem;
}
.btn:hover {
  transform: scale(1.03);
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn:disabled:hover {
  transform: none;
}

.btn-padding {
  padding: 0.75rem 1.875rem 0.75rem 1.25rem;
}

.react-datepicker {
  font-family: Poppins !important;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: left;
  color: #2b3e60 !important;
  font-size: 1rem;
  font-style: normal;
  border-radius: 0.9375rem !important;
  border: #486497 solid 1px;
  line-height: 1.25rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: inherit !important;
}

.react-datepicker__day-name {
  color: inherit !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-top-left-radius: 1.3rem !important;
  border-top-right-radius: 1.3rem !important;
}

.react-datepicker__day {
  color: #2b3e60 !important;
}

.react-datepicker__day--selected {
  background-color: #486497 !important;
  color: white !important;
}

.input-team-add {
  width: 33.3%;
  display: flex;
  background-image: url("../components/product/assets/plus-team.svg");
  background-position: 12px center;
  background-repeat: no-repeat;
  padding-left: 40px !important;
}

.team-panel {
  padding: 1.25rem 1.25rem;
}

.badge-team {
  border-radius: 3.125rem;
  color: white;
  display: flex;
  padding: 0.2rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: max-content;
  font-size: 0.95rem;
  font-weight: 700;
  font-size: 10px;
}

.badge-team.pending_submission {
  background-color: #ffa500;
}

.badge-team.accepted {
  background-color: #11a711;
}
.team-history-btn {
  display: flex;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.75rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  flex-shrink: 0;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.p-2 {
  padding: 0.75rem 1.875rem 0.75rem 1.25rem;
}
.text-gray {
  color: #535272;
}
.opacity-gray {
  opacity: 0.5;
}
.text-admin {
  color: #363565;
}

.uppercase {
  text-transform: capitalize;
}

.transaction-divider {
  border: 1px solid #e2e5eb;
  margin: 1rem 0;
}

.text-cross {
  text-decoration-line: line-through;
}

.badge-plan-affiliate {
  display: flex;
  padding: 0.25rem 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  border-radius: 0.3125rem;
  background: #e2f4ec;
}

.btn-per-page:focus {
  outline: none !important;
  box-shadow: none !important;
}

.separator-product {
  height: 1px;
  background: #eceff5;
  border: none;
}

.markdown {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: left;
  color: #2b3e60;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

form .ctn-document-2 {
  display: flex;
  background-image: url("../components/product/assets/doc-name.svg");
  background-position: 20px center;
  background-repeat: no-repeat;
  padding-left: 50px !important;
}

.p-dialog-mask.p-component-overlay {
  z-index: 9999 !important;
}
