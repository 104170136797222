.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, -0.5); /* Semi-transparent black */
  box-sizing: border-box; /* Include padding and border in total width and height */
  z-index: 11;
}

.modal-main {
  position: fixed;
  width: 35%;
  height: auto;
  padding: 4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid rgba(181, 179, 179, 0.3);
  border-radius: 10px;
}

.display-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  color: #2b3e60;
}

.close-modal {
  position: fixed;
  top: 5px;
  right: 0px;
  background: transparent;
}

.modal-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #645feb;
  display: flex;
  column-gap: 2rem;
}

.project-btn {
  background: #f5f8ff;
  border: 0.5px solid #e8e8e8;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  padding: 14px 20px;
  width: 100%;
}

.project-name {
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0rem;
}

.modal-sidebar {
  /* position: fixed;
  top: 0;
  left: 0; */
  /* width: 16rem; */
  height: 100%;
  z-index: 11;
  /* cursor: pointer; */
}

.modal-main-sidebar {
  position: fixed;
  width: 600px;
  height: auto; /* You might want to set a specific height */
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #e2e5eb;
  cursor: default;
  z-index: 9999;
  backdrop-filter: blur(10px);
}

.modal-main-admin-modal-project {
  /* width: 15rem; */
  /* top: 11rem; */
  /* left: 1.2rem; */
  height: auto;
  background: #ffffff;
  /* border: 0.5px solid #E8E8E8; */
  border-radius: 5px;
  cursor: default;
  z-index: 9999;
}

.close-modal-sidebar {
  position: relative;
  top: 5px;
  right: 0px;
  background: transparent;
}
