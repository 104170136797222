.p-dialog-footer {
  display: flex;
  justify-content: center;
}
.p-dialog {
  box-shadow: none !important;
}

.p-button-label {
  font-weight: 400 !important;
  font-size: 14px;
  color: white;
}
.p-confirm-dialog-reject {
  background-color: #d1242f !important;
}
.p-button-icon-left {
  margin: 0 !important;
}

.text-error .p-button-label {
  color: #d1242f !important;
}

.p-dialog .p-dialog-footer button span {
  margin: 0 !important;
}

.p-button.p-button-text {
  color: #486497 !important;
}

.p-button.p-confirm-dialog-accept {
  background: #486497 !important;
}
.p-dialog .p-dialog-header {
  color: #2b3e60 !important;
}
.p-button {
  border: none !important;
  height: fit-content;
}

.confirm-dialog-error {
  border: 1px solid #dc2626 !important;
  border-radius: 15px !important;
}
.confirm-dialog-error .p-dialog-header .p-dialog-title {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #dc2626;
}

.confirm-dialog-error .p-confirm-dialog-message {
  margin: 0 !important;
}

.confirm-dialog-error .p-button.p-confirm-dialog-accept {
  border: 1px solid #e2e5eb !important;
  background-color: #fae2e2 !important;
  border-radius: 10px !important;
}

.confirm-dialog-error .p-button.p-confirm-dialog-accept .p-button-label {
  color: #dc2626 !important;
}

.confirm-dialog-error .p-button.p-confirm-dialog-reject {
  border: 1px solid #e2e5eb !important;
  background-color: white !important;
  border-radius: 10px !important;
}

.confirm-dialog-error .p-button.p-confirm-dialog-reject .p-button-label {
  color: #486497 !important;
}

.confirm-dialog-error .p-dialog-footer {
  column-gap: 20px !important;
}
