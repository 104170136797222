.container-dropzone {
    height: 100%;
    cursor: pointer;
}

.dropzone-text {
    text-align: center;
    font-size: 12px;
    color: #2B3E60;
    display: flex;
    flex-direction: column;
    margin:auto;
}