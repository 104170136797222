.global-layout {
  display: flex;
  width: 100%;
}

.left-part {
  background: linear-gradient(229.05deg, #645feb -0.01%, #00e1d4 100%);
  border-radius: 10px 0px 0px 10px;
  padding: 10rem;
}

.right-part {
  display: flex;
  padding: 1.25rem 3.125rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.9375rem;
  border: 1px solid #e2e5eb;
  background: #fff;
}

.right-layout {
  width: 24rem;
}

@media screen and (max-width: 600px) {
  .global-layout {
    display: flex;
    width: 100%;
  }

  .left-part {
    display: none;
  }

  .right-part {
    padding: 1rem;
    height: 100vh;
    width: 100%;
    justify-content: center;
    margin: auto;
    flex-direction: unset;
  }
}

@media screen and (min-width: 601px) {
  .global-layout {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .left-part {
    display: none;
  }

  .right-layout-logo {
    display: flex;
    margin: 0 auto;
  }
}
