.card-dashboard:hover {
  transform: scale(1.03);
}

.card-dashboard-disabled {
  filter: blur(1px);
}

.card-dashboard-disabled:hover {
  filter: blur(2px);
  border-width: 2px 2px 4px 2px !important;
  border-style: solid !important;
  border-color: #645feb !important;
  cursor: not-allowed !important;
}
