/* BLOCK PRODUCTS - DOCUMENT */
.container .ctn-document label.title-document {
  font-weight: 600 !important;
  color: #242353 !important;
  font-size: 16px !important;
}
.container .ctn-document input {
  width: 30%;
  display: flex;
  background-image: url("../assets/doc-name.svg");
  background-position: 20px center;
  background-repeat: no-repeat;
  padding-left: 50px !important;
}
.container .ctn-document input::placeholder {
  font-weight: 400;
  font-size: 13px;
}
.settings-button {
  width: 36px;
  margin-left: 10px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s;
  padding: 8px;
}
.settings-button:hover {
  background-color: #f5f8ff;
}
.settings-button img {
  width: 18px;
  height: 18px;
}

.rounded-input {
  border-radius: 10px;
  background: #ffffff;
  padding: 12px 20px 12px 20px;
  font-size: 14px;
  transition: all 0.2s;
  border: 1px solid #e2e5eb;
  box-shadow: 0px 2px 20px 0px #acacc940;
  display: flex;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.rounded-xl-input {
  border-radius: 10px;
  background: #ffffff;
  padding: 12px 12px;
  font-size: 14px;
  transition: all 0.2s;
  border: 1px solid #e2e5eb;
  box-shadow: 0px 2px 20px 0px #acacc940;
  display: flex;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.rounded-input:hover,
.rounded-xl-input:hover {
  box-shadow: 0px 10px 20px rgba(220, 219, 237, 0.6);
}
.rounded-input:focus,
.rounded-xl-input:focus {
  outline: 1px solid #bbb8ff;
}

.rounded-input:disabled,
.react-dropdown-select:disabled {
  cursor: not-allowed;
  color: rgb(170, 170, 170);
}
select.rounded-input {
  cursor: pointer;
  padding: 11.5px 20px;
}

.button-product {
  display: flex;
  height: 2.75rem;
  padding: 12px 20px 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e5eb;
  background: #fff;
  cursor: pointer;
}

.button-product:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-product div {
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.output-response {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  width: 100%;
  height: auto;
  min-height: 10rem;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  column-gap: 1rem;
  position: relative;
  /* box-shadow: 0 10px 20px rgba(220, 219, 237, 0.3); */
  transition: all 0.2s;
}
/* .output-response:hover {
  box-shadow: 0 10px 20px rgba(220, 219, 237, 1);
} */

.output-response p {
  margin: 0px;
}
.history-output .markdown {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
/* DESIGN OUTPUT HTML ON PRODUCT AND HISTORY (FAQ AND BLOG) */
.history-history-steps h1,
.output-response h1,
.history-output h1 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #2b3e60;
}

.history-history-steps h2,
.output-response h2,
.history-output h2 {
  font-size: 20px;
  font-weight: 600;
}
.history-history-steps h3,
.output-response h3,
.history-output h3 {
  font-size: 18px;
  font-weight: 600;
  white-space: normal;
}
.history-history-steps h4,
.output-response h4,
.history-output h4 {
  font-size: 16px;
  font-weight: 600;
  white-space: normal;
}
.output-response p,
.history-output p,
.history-history-steps p {
  font-size: 14px;
  line-height: 1.5em;
  white-space: normal;
  /* font-style: italic !important; */
  color: #2b3e60;
}

.history-history-steps strong,
.output-response strong,
.history-output strong {
  font-weight: 600;
}
.history-history-steps ul,
.history-history-steps ol,
.output-response ul,
.history-output ul,
.output-response ol,
.history-output ol {
  margin: 0;
}
.history-history-steps ul,
.history-history-steps li,
.output-response ul li,
.history-output ul li,
.output-response ol li,
.history-output ol li {
  font-size: 14px;
  line-height: 1.5em;
  white-space: normal;
  /* font-style: italic !important; */
  color: #2b3e60;
  margin: 0;
}

.area-product {
  padding: 1rem;
}

.output-screebot {
  text-align: left;
  font-size: 16px;
  height: auto;
  min-height: 10rem;
  line-height: 1.4em;
  color: #2b3e60;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.ctn-loader {
  /* width: 60px; */
  height: 25px;
  background-image: url("../../../assets/logo_loader.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
}
.ctn-loader lottie-player {
  min-width: 46px !important;
  max-width: 46px !important;
  margin-right: 10px;
}
.ctn-loader span {
  font-size: 14px;
}

.copy-text {
  display: flex;
  width: max-content;
  height: auto;
  background: #645feb;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  top: -50px;
  right: 0px;
  align-items: center;
  padding: 8px 15px 8px 10px;
  gap: 10px;
  transition: all 0.2s;
}
/* ctn-loader for metas : go down ! */

.copy-text:hover {
  opacity: 0.8;
}

.copy-text span,
svg {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

input::placeholder,
textarea::placeholder {
  color: #888888;
  font-size: 14px;
}

.obligatory::after {
  content: "*";
  color: #2b3e60;
  /* font-size: 14px;
  margin-left: 5px; */
}
.output-response-meta-title .copy-text-meta,
.output-response-meta-description .copy-text-meta {
  top: 0px;
  right: -110px;
}
.output-response-meta-description {
  max-height: inherit;
}
.output-response-meta-description span.output-screebot {
  line-height: 1.5rem;
  margin: -0.8rem 0;
  min-height: inherit;
}
.ctn-loader-metas {
  width: 40px !important;
  height: 23px !important;
  position: absolute !important;
  top: 5px;
  left: 5px;
}
.ctn-loader-metas lottie-player {
  width: 40px !important;
  height: 23px !important;
}

.count-char {
  display: flex;
  flex-direction: row;
  color: #5b5c6d;
  font-size: 13px;
  font-weight: 500;
  margin: 0 5px 0 0;
}

.few-characters {
  color: #ed4b0d;
  font-weight: 600;
}
/* EXCEPTION SMALL TEXTAREA */
.input-small {
  max-height: 6em;
}

.button-copy-list {
  position: absolute;
  top: -70px;
  right: 0px;
  display: flex;
  column-gap: 1.5rem;
}

.btn-copy {
  display: flex;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.75rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e5eb;
  box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.25);
}

.btn-copy.copy {
  background: #fff;
}
.btn-copy.copied {
  background: #486497;
  color: white;
}
.btn-copy img {
  fill: currentColor;
}

.button-copy-list-history .btn-copy {
  width: fit-content !important;
  height: fit-content !important;
}
/* .copy-text-2 {
    display: flex;
    width: max-content;
    height: auto;
    background: #645FEB;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 8px 15px 8px 10px;
    gap: 10px;
    transition: all .2s;
} */

/* ctn-loader for metas : go down ! */

.copy-text-2:hover {
  opacity: 0.8;
}

.copy-text-2 span,
svg {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.copy-html {
  display: flex;
  width: max-content;
  height: auto;
  background: #f7622c;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 8px 15px 8px 10px;
  gap: 10px;
  transition: all 0.2s;
}

.copy-html:hover {
  opacity: 0.8;
}

.copy-html span,
svg {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.c-radio-button {
  position: relative;
}
.c-radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.c-radio-button label {
  border-radius: 5px;
  color: #493e3e;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  margin: 0;

  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.5;
  transition: all 0.2s;
  box-shadow: 0px 10px 20px rgba(220, 219, 237, 0.3);
  white-space: nowrap;
}
.c-radio-button label:hover {
  box-shadow: 0px 10px 20px rgba(220, 219, 237, 1);
}

.c-radio-button input[type="radio"]:checked ~ label {
  opacity: 1;
  box-shadow: 0px 10px 20px rgba(220, 219, 237, 1);
}

.c-radio-button.disabled label {
  cursor: not-allowed;
}

.linkedin {
  column-gap: 1.2rem;
  padding: 0.6rem 2rem 0.6rem 1.5rem;
  background-color: #0a66c2;
}

.facebook {
  column-gap: 1.2rem;
  padding: 0.6rem 2rem 0.6rem 1.5rem;
  background-color: #4460a0;
}

.instagram {
  column-gap: 1.2rem;
  padding: 0.6rem 2rem 0.6rem 1.5rem;
  background: linear-gradient(270deg, #9638b9 0%, #f8953e 100.74%);
}

.excel {
  column-gap: 0.6rem;
  padding: 8px 12px 8px 12px;
  background-color: #28955e;
}

.basic {
  column-gap: 0.6rem;
  padding: 8px 12px 8px 12px;
  background-color: #28955e;
}

.sheet {
  column-gap: 0.6rem;
  padding: 8px 12px 8px 12px;
  background-color: #28955e;
}

.create {
  column-gap: 0.6rem;
  padding: 8px 12px 8px 12px;
  background-color: #5b5c6d;
}

.explain {
  column-gap: 0.6rem;
  padding: 8px 12px 8px 12px;
  background-color: #5b5c6d;
}

.soon-product {
  position: absolute;
  top: -10px;
  right: -10px;
  background: linear-gradient(270deg, #645feb 0%, #00e1d4 100.74%);
  opacity: 1;
  padding: 2px 10px;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 400;
}

.fieldbackBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.8rem;
  background: transparent;
  border: none;
  z-index: 9;
}

.fieldbackBtn svg {
  color: #645feb;
  opacity: 0.5;
}

.fieldbackBtn:disabled {
  cursor: not-allowed;
}

.fieldbackBtn:disabled svg {
  color: rgb(170, 170, 170);
}

.textarea-context {
  height: 10em;
  max-height: inherit;
}

pre.formula-result {
  display: block;
  padding: 12px;
  margin: 0;
  color: #f60;
  background: #f5f5f5;
  white-space: pre-line !important;
}

/* Largeur de la barre de défilement */
::-webkit-scrollbar {
  width: 2px;
}

/* Couleur de la poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background: #dde4ff;
  border-radius: 50px;
}

/* Couleur de la piste de la barre de défilement */
::-webkit-scrollbar-track {
  background: white;
}
