.custom-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-between; */
    column-gap: 4rem;
}

@media screen and (max-width: 1024px) {
    .custom-row {
        flex-direction: column;
    }
}