@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.light {
  background: #f7f8ff;
}

input:focus,
textarea:focus,
select:focus,
.btn:not:focus,
button:not(.fieldbackBtn):not(.sidebar-button-arrow):focus:not(
    .p-button
  ):focus {
  outline-color: #c5ccda;
  outline-style: solid;
  outline-width: 1px;
}
button {
  outline: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  font-size: 14px;
}
button:hover {
  transform: scale(1.01);
}

input::placeholder,
textarea::placeholder {
  color: #b1b1b1 !important;
}

* {
  box-sizing: border-box;
}

.p-button:focus {
  box-shadow: none !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, -0.5); /* Semi-transparent black */
  z-index: 9998; /* One less than the modal's z-index */
  box-sizing: border-box; /* Include padding and border in total width and height */
}
.disabled-blur {
  filter: blur(2px);
  cursor: not-allowed;
  color: rgba(0, 0, 0, -0.5);
}

@layer components {
  .rounded-custom-input {
    border-radius: 0.635rem !important;
  }
  .rounded-card {
    border-radius: 0.938rem !important;
  }
  .border-gray-custom-input {
    border-color: #e2e5eb;
  }
  .border-secondary-custom-input {
    border-color: #486497;
  }
  .border-white {
    border-color: white;
  }
  .padding-custom-input {
    padding: 12px 40px 12px 20px !important;
  }
  .padding-custom-button {
    padding: 12px 20px 12px 14px;
  }
  .padding-custom-box {
    padding: 30px 40px 40px 40px;
  }
  .padding-custom-badge {
    padding: 4px 12px;
  }
  .padding-custom-switch {
    padding: 8px 18px 8px 18px;
  }
  .padding-custom-blue-button {
    padding: 16px 45px 16px 50px;
  }
  .padding-custom-project {
    padding: 10px 14px 10px 14px;
  }
  .text-primary {
    color: #2b3e60;
  }
  .text-blue-affiliate {
    color: #00718a;
  }
  .text-secondary {
    color: #486497;
  }
  .text-white {
    color: white;
  }
  .text-error {
    color: #dc2626;
  }
  .text-success {
    color: #059669;
  }
  .text-gray-custom {
    color: #aab2bf;
  }
  .text-warning {
    color: #d97706 !important;
  }
  .text-blue-demo {
    color: #4f46e5 !important;
  }
  .bg-primary {
    background-color: #2b3e60 !important;
  }
  .bg-green-500 {
    background-color: #059669 !important;
  }
  .bg-secondary {
    background-color: #486497 !important;
  }
  .bg-error {
    background-color: #fae2e2 !important;
  }
  .bg-success {
    background-color: #e1fff1 !important;
  }
  .bg-warning {
    background-color: #fcf7e2 !important;
  }
  .bg-blue-demo {
    background-color: #eef2ff !important;
  }
  .bg-blue-admin {
    background-color: #3f5a85 !important;
  }
  .bg-blue-switch {
    background-color: #f5f8ff !important;
  }
  .bg-blue-button {
    background: linear-gradient(90deg, #60f4fe 0%, #d2c0fc 98.95%) !important;
  }
  .bg-blue-affiliate {
    background-color: #e0f9ff !important;
  }
  .bg-gray-500 {
    background-color: #aab2bf !important;
  }
  .border-error {
    border-color: #dc2626 !important;
  }
  .card-dashboard {
    display: flex;
    padding: 30px 40px 30px 30px;
    width: 23.5%;
    background: white;
    border-radius: 15px;
    column-gap: 2rem;
    transition: 0.2s;
    cursor: pointer;
    min-height: 195px;
    flex-direction: column;
    row-gap: 1rem;
  }
  .ring-primary {
    outline-color: #c5ccda;
  }
  .bg-white {
    background-color: white;
  }
  .bg-light-blue {
    background-color: #f5f8ff !important;
  }
  .react-dropdown {
    padding: 0.75rem 1.25rem !important;
  }
  .button-blue-modal {
    box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.2);
  }
  .button-blue-modal:hover {
    box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.15);
  }
  .button-modal-shadow {
    box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.2);
  }
  .history-output h1 {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .bottom-menu .ps-menu-button .ps-menu-icon {
    opacity: 1;
  }
}

@layer base {
  h1 {
    @apply text-2xl font-semibold leading-8;
  }
  h2 {
    @apply text-lg font-semibold leading-7;
  }
  h3 {
    @apply text-base font-medium leading-6;
  }
  h4 {
    @apply text-sm font-normal leading-5;
  }
  p {
    @apply font-normal text-sm leading-5;
  }
  input:not([tabindex="-1"]),
  textarea {
    @apply bg-white border border-gray-custom-input padding-custom-input rounded-custom-input text-primary focus:outline focus-visible:outline focus:outline-1 focus-visible:outline-1 focus:ring-primary focus-visible:ring-primary text-sm;
  }
  input:disabled {
    @apply opacity-60;
  }
  .react-dropdown-select {
    @apply bg-white border react-dropdown border-gray-custom-input rounded-custom-input text-primary focus:outline focus-visible:outline focus:outline-1 focus-visible:outline-1 focus:ring-primary focus-visible:ring-primary text-sm !important;
  }
  button {
    @apply rounded-custom-input padding-custom-button;
  }
  button.btn-per-page {
    @apply p-0;
  }
  .primary-btn {
    @apply bg-primary text-white;
  }
  .secondary-btn {
    @apply bg-secondary text-white flex flex-row items-center justify-center gap-x-4;
  }
  .items-per-page {
    @apply flex flex-row gap-x-7 bg-white border border-gray-custom-input padding-custom-button rounded-custom-input text-primary focus:outline-1 focus-visible:outline-1 focus:ring-primary focus-visible:ring-primary !important;
  }
  .button-modal {
    @apply w-fit bg-blue-button text-white padding-custom-blue-button border-[1px] border-white button-modal-shadow;
  }
  .button-modal:hover {
    @apply bg-blue-button text-white;
  }
  .ps-submenu-root > .ps-menu-button > .ps-menu-label.ps-open {
    @apply font-medium text-secondary;
  }
  .ps-submenu-root > .ps-menu-button > .ps-menu-icon.ps-open {
    @apply text-secondary;
  }
  .ps-submenu-root > .ps-menu-button > .ps-menu-label {
    @apply text-sm;
  }
  .ps-menu-button.ps-open {
    @apply bg-light-blue;
  }
}
