.Toastify__toast-container--bottom-right {
    min-width: 28rem !important;
}

.Toastify__toast {
    padding: 14px 20px !important;
    border-radius: 25px !important;
    box-shadow: 0px 10px 20px rgba(220, 219, 237, .7) !important;
    transition:.2s !important;
}
.Toastify__toast:hover {
    box-shadow: 0px 10px 20px rgba(220, 219, 237, 1) !important;
    transform: scale(1.01) !important;
}
.Toastify__toast-icon {
    margin-right: 20px !important;
}

.custom-toast {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.title-toast {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #5B5C6D;
}

.subtitle-toast {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #5B5C6D;
}