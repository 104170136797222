.title-history {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}
.history-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  color: white;
}

.history-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* .history-btn:focus:not(:disabled), .history-btn:hover:not(:disabled) {
    opacity: 0.8;
} */

.history-plan {
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;
}

.history-line {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.history-product {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
}

.history-title {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-transform: capitalize;
}

.history-btn-2:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.history-output {
  font-family: Poppins;
  /* font-size: 24px;
  font-weight: 700; */
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  color: #486497;
}

.history-title-document {
  font-weight: 500;
  text-transform: initial;
  font-size: 1rem;
}

.history-date {
  text-transform: initial;
  font-size: 0.875rem;
  margin-top: auto;
  margin-bottom: auto;
}

.history-status-wip,
.history-status-introduction,
.history-status-conclusion,
.history-status-redaction {
  background: #fcf7e2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  color: #d97706;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  height: fit-content;
  border: 1px solid #ffffff;
  border-radius: 5px !important;
}

.history-status-summary,
.history-status-valid,
.history-status-end {
  background: #e1fff1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  color: #059669;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  height: fit-content;
}

.history-status-error {
  background: #fae2e2;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  color: #dc2626;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  height: fit-content;
}

.paginate {
  display: inline-flex;
  list-style: none;
  gap: 2rem;
  margin: 1rem 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}

.paginate-affiliater {
  margin: 0 !important;
}

.paginate li {
  cursor: pointer;
}

.paginate .selected [role="button"] {
  cursor: not-allowed;
  opacity: 0.5;
}

.previous.disabled [role="button"] {
  cursor: not-allowed;
  opacity: 0.5;
}
.previous {
  cursor: pointer;
}
.next.disabled [role="button"] {
  cursor: not-allowed;
  opacity: 0.5;
}
.next {
  cursor: pointer;
}

/* METAS DESIGN ON HISTORY PAGE FOR WEB PRODUCT */
.history-output .output-metas {
  margin-top: 40px;
  line-height: 1.5em;
  color: #4d5072;
}
.output-metas strong {
  margin-right: 10px;
  display: block;
}
.highlighted {
  color: #645feb;
  font-weight: 700;
  text-transform: initial;
}
.padding-see {
  padding: 0.75rem 0.825rem;
}
.padding-back {
  padding: 0.75rem 1.875rem 0.75rem 1.25rem;
}

.bg-img-history-paginated img {
  width: 20px;
  height: 20px;
}
