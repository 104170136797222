.account-title {
    padding-bottom: 5rem;
}

.account-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.account-text-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #676767;
}

.account-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.account-field-input {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    padding: 9px 15px;
}

.account-btn {
    display: flex;
    height: 2.5625rem;
    padding: 0.75rem 1.875rem 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.9375rem;
    color: white;
    border-radius: 0.625rem;
    border: 1px solid  #E2E5EB;
    background:  #486497;
    box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.25);
}

/* .account-btn:focus, .account-btn:hover {
    opacity: 0.9;
} */

.account-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.account-plan {
    display: inline-block;
    width: fit-content;
    background: #FFFFFF;
    padding: 25px;
}

.account-plan-title {
    line-height: 24px;
}

.account-plan-word {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.btn-account-forfait {
    background: #EBF0FF;
    border-radius: 50px;
    padding: 6px 20px 6px 6px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    width: fit-content;
}
.btn-account-forfait .account-plan-text {
    font-size: 14px;
    margin-top: 0px!important;
}

.pwd-container {
    position: relative;
}

.show-password {
    cursor: pointer;
    position: absolute;
    /* width: 20px; */
    right: 25px;
    bottom: 10px;
}