.screebot-card {
  border-radius: 0.9375rem;
  border: 1px solid var(--border, #e2e5eb);
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(172, 172, 201, 0.25);
  padding: 30px 25px;
}

.small-card-screebot {
  display: flex;
  padding: 0.625rem 1.25rem;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.9375rem;
  transition: all 0.2s;
}

.small-card-screebot:focus,
.small-card-screebot:hover {
  transform: scale(1.03);
}

.small-card-screebot:nth-child(odd) {
  background: #f8f8fb !important;
}
.small-card-screebot:nth-child(even) {
  background: white !important;
}

.affiliation-link {
  flex-wrap: wrap;
  align-content: center;
  display: flex;
}

table {
  border: 1px solid #b3adad;
  border-collapse: collapse;
  padding: 5px;
}
table th {
  border: 1px solid #b3adad;
  padding: 5px;
  background: #f0f0f0;
  color: #313030;
}
table td {
  border: 1px solid #b3adad;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  color: #313030;
}
