.header-sections {
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
  background: white;
  max-width: -webkit-fill-available;
  max-width: stretch;
  max-width: -moz-available;
  width: 100%;
}

.header-first-section {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
}

.header-avatar {
  width: 75px;
  height: 75px;
  border-radius: 4.4375rem;
}

.header-second-section {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  column-gap: 1.875em;
}

.help-button {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.8rem;
  padding: 0px 16px;
  transition: all 0.2s;
  border-radius: 10px;
  border: 1px solid #e2e5eb;
}

.logo-svg {
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.card-token {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  transition: all 0.2s;
}
.card-token:hover {
  transform: scale(1.03);
}
.card-token-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  padding: 16px 16px 16px 16px;
  column-gap: 1rem;
  user-select: none;
  border: 1px solid #e2e5eb;
  border-radius: 10px;
}
.card-token .nameoftokens {
  /* color: #645FEB; */
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}

/* SUPPRESSION TEMPORAIRE DE L'ICONE + DANS LES TOKEN DU HEADER */
.card-token svg {
  display: none;
}

.title {
  margin-bottom: 25px;
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 18px;
  }
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
  padding: 8px 10px !important;
}

.ReactFlagsSelect-module_selectFlag__2q5gC svg {
  width: 2rem;
}

.ReactFlagsSelect-module_selectValue__152eS {
  padding: 0 !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0 !important;
}

.menu-flags button {
  height: 100%;
  background: white;
  border-radius: 10px;
  border: 0 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  border-radius: 10px !important;
  border: 0 !important;
  margin-top: 6px !important;
}

.menu-flags:hover {
  border-radius: 10px;
}

.menu-flags > button > span > .ReactFlagsSelect-module_label__27pw9 {
  display: none;
}

.menu-flags > ul {
  width: 11rem;
  max-height: fit-content;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu-flags ul {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  transition: all 0.2s !important;
  /* right: unset;
    left: unset; */
  top: 3rem;
  left: 0rem;
}
