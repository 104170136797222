.ps-sidebar-container {
  display: flex;
  flex-direction: column;
}
.sidebar aside {
  width: 280px;
}
.side_bar_button_collapsed {
  display: block;
  transition: all 0.4s;
}

.side_bar_button_not_collapsed {
  display: block;
  transform: rotate(180deg);
  transition: all 0.4s;
}

.side_bar_logo_collapsed {
  height: 8.5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin: auto;
  transition: all 0.4s;
}

.side_bar_logo_collapsed:hover {
  transform: scale(1.1) rotateZ(-2deg);
}

.side_bar_logo_not_collapsed {
  max-height: 5rem;
}

.ps-menuitem-root a.active,
.ps-menuitem-root .ps-menu-button.ps-active {
  border-left: #486497 3px solid;
  font-weight: 600;
  background-color: #fcfdff;
  color: #486497;
}

.ps-menuitem-root a.active .ps-menu-icon {
  opacity: 1;
}
.ps-menuitem-root a .ps-menu-icon {
  opacity: 0.7;
}

.ps-menuitem-root a {
  border-left: #efefef 3px solid;
}

.ps-menuitem-root {
  color: #b1b1b1 !important;
}

.ps-menuitem-root.ps-disabled {
  cursor: not-allowed;
}
/* .top-menu .ps-menuitem-root:first-child {
  height: 45px;
} */
.bottom-menu {
  padding-bottom: 15px;
}

.sidebar-button-arrow {
  background: #fbfbfb;
  border-radius: 50px;
  position: relative;
  left: -10px;
  transform: translateY(-50%);
  z-index: 10;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 19px;
}

.nav-member .react-slidedown .ps-menuitem-root a.active {
  color: #f3f3f3;
  font-weight: 500;
}

.ps-menu-icon {
  margin-right: 20px !important;
}
.ps-menu-button {
  transition: all 0.2s;
  height: 50px !important;
}
.ps-menu-button:hover {
  background-color: rgba(220, 219, 237, 0.3) !important;
  transition: background-color 0.2s all;
  transform: scale(1.03);
}
.ps-menu-button:focus-visible {
  background-color: rgba(220, 219, 237, 0.3) !important;
  transition: background-color 0.2s all;
  transform: scale(1.03);
  outline: none;
}

.my-account-sidebar {
  display: inline-flex;
  padding: 1.375rem 1.875rem 1.25rem 1.875rem;
  flex-direction: column;
  justify-content: center;
  gap: 0.875rem;
  z-index: 99999;
  left: 0rem;
  bottom: 65px;
  position: fixed;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
  border: 1px solid #e2e5eb;
  background: #fff;
  transition: all.2s;
}

.my-account-sidebar-link {
  overflow: hidden;
  color: #486497;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 1.25rem;
  column-gap: 1rem;
  display: flex;
  cursor: pointer;
}

.my-account-sidebar-link svg {
  margin-top: auto;
  margin-bottom: auto;
}

.my-account-sidebar-link:hover {
  font-weight: 600;
}

.my-account-sidebar-link:hover span {
  font-weight: 600;
}

.border-my-account {
  border: 0.5px solid #e8e8e8;
  width: 100%;
}

.border-my-project {
  border: 1px solid #b1b1b1;
  width: 100%;
}

.ps-menu-label {
  font-size: 14px;
  overflow: visible;
  line-height: initial;
  text-align: left;
  font-weight: 400;
}

.button-payment-sidebar {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  /* margin-bottom: 20px; */
}

.button-payment {
  width: 100%;
  background-size: 200% 100%;
  border-radius: 15px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 50px 12px 40px;
  gap: 15px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  border-radius: 0.9375rem;
  border: 1px solid #e2e5eb;
  background: #486497;
}
.button-payment:hover {
  background-position: -100% 0;
}
.button-payment svg {
  transition: 0.2s;
}
.button-payment:hover svg {
  transform: scale(1.2);
}

.project-sidebar {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 1rem;
}

.project-sidebar:focus-visible {
  outline: none;
}

a:focus-visible {
  outline: none;
}

.project-admin-list:focus-visible {
  outline: none;
}

.project-admin-list {
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.z-index-button {
  z-index: 0;
}

.ps-sidebar-container {
  background-color: white !important;
}

.btn-logout {
  display: flex;
  width: 13.625rem;
  padding: 0.5rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e5eb;
  background: #fff;
}

.ps-submenu-content {
  overflow: hidden !important;
}

.transaction-divider-sidebar {
  border: 0.5px solid #e2e5eb;
  /* margin: 0.25rem 0; */
}

.ps-submenu-expand-icon {
  display: flex;
  align-items: center;
}

/* .ps-menu-root ul {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  column-gap: 12px;
} */
nav.ps-menu-root > ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

nav.bottom-menu > ul {
  gap: 0px !important;
}

.bottom-menu .button-payment-sidebar {
  padding-bottom: 20px;
}
